import { t } from "ttag";

import Tooltip from "metabase/core/components/Tooltip";

import { StoreIcon, StoreIconRoot, StoreIconWrapper } from "./StoreLink.styled";

const StoreLink = () => {
  return null;
};

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default StoreLink;
